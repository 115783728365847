import React from "react"

import Layout from "../global/components/layout"
import { Component } from "react"
import SEO from "../global/components/seo"

class PricePage extends Component {

  constructor() {
    super();
    
    this.state = {
      load: false,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        load: true,
      });
    }, 0);
  }

  render() {
    return <Layout>
        <SEO title="Bienvenido" />
        Precio
      </Layout>
  }
}


export default PricePage
